import absoluteUrl from 'next-absolute-url'
import urlJoin from 'url-join'
import Error from 'next/error'
import {curry} from '@cullylarson/f'
import FourOhFour from '@@app/pages/404'
import {ok} from './request'

export const isServer = () => typeof window === 'undefined'
export const isBrowser = () => !isServer()

// Takes a promise. If it returns a status param, will set the response statusCode to that status. Returns the {result, status}.
// Useful inside getIntialProps.
export const respondStatus = curry((res, p) => {
    return p.then(result => {
        if(res && result?.status) res.statusCode = result.status

        return {
            ...result,
            status: result?.status,
            ok: ok(result?.status),
        }
    })
})

export const augFullUrl = curry((req, p) => {
    return p.then(result => {
        const pathname = (req ? req.url : window.location.pathname) || ''

        const fullUrl = urlJoin(absoluteUrl(req).origin, pathname)

        return {
            ...result,
            fullUrl,
        }
    })
})

// Wraps a nextjs page. If the ok prop isn't true, will render an error page. Otherwise will render the page normally.
export const withErrorPage = f => ({ok, status, errors, ...rest}) => {
    if(!ok) {
        if(status === 404) {
            return <FourOhFour />
        }
        else {
            if(errors && errors.length) {
                return <Error statusCode={status} title={errors.join(' ')} />
            }
            else {
                return <Error statusCode={status} />
            }
        }
    }
    else {
        return f({ok, status, errors, ...rest})
    }
}

// route using nextjs router and scroll to the top of the page
export const routeToTop = (router, ...args) => {
    // can't use router on the client
    if(!isBrowser()) {
        return
    }

    window.scrollTo(0, 0)

    return router.push.apply(router, args)
}

// route using nextjs router and scroll to the top of the page
export const replaceToTop = (router, ...args) => {
    if(isBrowser()) window.scrollTo(0, 0)

    return router.replace.apply(router, args)
}
