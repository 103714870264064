import * as actionTypes from './action-types';
export var kinds = {
  ERROR: 'error',
  NOTICE: 'notice',
  SUCCESS: 'success'
};

function addNotification(type, msg, kind, _ref) {
  var _ref$allowManualDimis = _ref.allowManualDimiss,
      allowManualDimiss = _ref$allowManualDimis === void 0 ? true : _ref$allowManualDimis,
      _ref$autoDismiss = _ref.autoDismiss,
      autoDismiss = _ref$autoDismiss === void 0 ? true : _ref$autoDismiss,
      _ref$dismissTimeout = _ref.dismissTimeout,
      dismissTimeout = _ref$dismissTimeout === void 0 ? 10000 : _ref$dismissTimeout;
  return {
    type: type,
    msg: msg,
    kind: kind,
    options: {
      autoDismiss: autoDismiss,
      dismissTimeout: dismissTimeout,
      allowManualDimiss: allowManualDimiss
    }
  };
}

export function addNotificationError(msg) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.ERROR, options);
}
export function addNotificationNotice(msg) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.NOTICE, options);
}
export function addNotificationSuccess(msg) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return addNotification(actionTypes.ADD_NOTIFICATION, msg, kinds.SUCCESS, options);
}
export function markNotificationShown(id) {
  return {
    type: actionTypes.MARK_NOTIFICATION_SHOWN,
    id: id
  };
}
export function removeNotification(id) {
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    id: id
  };
}