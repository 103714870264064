import {liftA, isObject} from '@cullylarson/f'
import {getStampMs} from '@@client/lib/dates'
import {hasProp} from '@@client/lib/objs'

export function bearer(token) {
    return `Bearer ${token}`
}

export function responseData(response) {
    return response.json()
        .then(data => ({response, data}))
}

export function ok(status) {
    return status && status >= 200 && status <= 299
}

// will cache a standard request for a certain amount of time.
// a standard request is one that returns a promise and may reject or resolve to
// something with {success: boolean}.
// if promise fails (exception or success === false or success undefined), will not cache.
export const cacheStandardRequest = (
    forPeriods, // how long to cache. can be an int for seconds or: {forS, forMs}
    f,
) => {
    const forS = isObject(forPeriods) ? forPeriods.forS : forPeriods
    const forMs = isObject(forPeriods) ? (forPeriods.forMs || forS * 1000) : forS * 1000
    const cache = {}

    const isFresh = (key) => {
        return hasProp(key, cache)
            ? (getStampMs() - cache[key].stampMs) <= forMs // is it too old?
            : false
    }

    const invalidate = key => {
        if(hasProp(key, cache)) delete cache[key]
    }

    const getFresh = (key, args) => {
        invalidate(key)

        const p = f(...args)
            .then(x => {
                if(!x.success) {
                    invalidate(key)
                }

                return x
            })
            .catch(err => {
                invalidate(key)
                throw err
            })

        cache[key] = {
            stampMs: getStampMs(),
            p,
        }

        return p
    }

    return (...args) => {
        const key = JSON.stringify(args)

        if(isFresh(key)) {
            return cache[key].p
        }
        else {
            return getFresh(key, args)
        }
    }
}

// Caches a successful request response. Will return the cached response for a certain amount of time.
// Once the cache is stale, will refresh the cache on the next calling request, but will still return the
// stale response until a new one is loaded. So, once you have a cached response, this will respond almost
// instantly with a response, even while it's refreshing.
export const cacheStaleWhileRefresh = (
    {
        forS = 600, // cache for this many seconds
        forMs = null, // cache for this many milliseconds. if set, will ignore `forS`
        enableStaleWhileRefresh = true, // if set to false, will not return a stale response while refreshing
        veryStaleS = 7200, // after this amount of time, a cached response will be considered very stale and wil not be returned. instead will make a new request and return that
        veryStaleMs = null, // same as veryStaleS, but uses ms resolution. if provided, will ignore veryStaleS.
        getCacheKey = args => JSON.stringify(args), // a function that will produce a cache key based on the arguments passed to `f` (passed as an array)
        retries = 0, // how many times to retry a request before considering it failed and returning the failed response (the first request does not count as a retry, so if retries is 3, will make 4 requests as most)
        retryStatusCodes = [[500, 599]], // one or more status codes that will trigger a retry. each item can be an int or an array representing a range of statuses, inclusively (e.g. [500, [400-499]] would retry on 500 or any status in the range or included 400-499)
        debug = false,
    } = {},
    f, // the function that will return a fetch response or an object with a .response key. The reason you'd want to set response as an object param is if you call e.g. response.json() on the response, in your function. If you try to call that outside your function, you'll get a "body used already" error on all but the first call to the function since it's returning the same, cached response every time
) => {
    retryStatusCodes = liftA(retryStatusCodes)
    forMs = forMs || forS * 1000
    veryStaleMs = veryStaleMs || veryStaleS * 1000

    let cache = {} // eslint-disable-line prefer-const

    const isCached = key => hasProp(key, cache)

    // NOTE: will return false if not cached
    const isFresh = (key) => {
        if(!isCached(key)) return false

        const age = getStampMs() - cache[key].stampMs

        debug && console.info({now: getStampMs(), cachedStamp: cache[key].stampMs, age, forMs})

        return age <= forMs
    }

    const getResponse = x => x ? (x.response || x) : x

    const isRetryStatus = status => {
        for(const testStatus of retryStatusCodes) {
            if(Array.isArray(testStatus) && testStatus.length === 2) {
                if(status >= testStatus[0] && status <= testStatus[1]) {
                    return true
                }
            }
            else if(status === testStatus) {
                return true
            }
        }

        return false
    }

    // we are in the process of refreshing
    const isRefreshing = key => {
        return isCached(key)
            ? Boolean(cache[key].refreshingP)
            : false
    }

    // NOTE: will return true if not cached
    const isVeryStale = key => {
        return isCached(key)
            ? (getStampMs() - cache[key].stampMs) >= veryStaleMs // is it too old?
            : false
    }

    const invalidate = key => {
        if(hasProp(key, cache)) {
            delete cache[key]
        }
    }

    const retry = (args, tries = 1) => {
        return f(...args)
            // catch here because we only want to retry if f() fails, not if our .then code below fails. if we caught below .then, we could potentially retry a successful request because of an issue in our code
            .catch(err => {
                debug && console.info('Got exception.', {tries, retries}, err)
                // we've tried as many times as we can
                if(tries > retries) {
                    debug && console.info('  Not retrying.')
                    throw err
                }
                else {
                    debug && console.info('  Retrying.')
                    return retry(args, tries + 1)
                }
            })
            .then(retValue => {
                const response = getResponse(retValue)

                // no status means f might not have returned a response. either way, we don't know what this is and can't figure out whether to retry
                if(!response.status) return response

                if(isRetryStatus(response.status)) {
                    debug && console.info('Got a status that could be retried.', {status: response.status, tries, retries})

                    if(tries > retries) {
                        debug && console.info('  Not retrying.')
                        return retValue
                    }
                    else {
                        debug && console.info('  Retrying.')
                        return retry(args, tries + 1)
                    }
                }
                // don't retry
                else {
                    debug && console.info('Got result.')
                    return retValue
                }
            })
    }

    const getImmediateResponse = (key, args) => {
        debug && console.info('Getting immediate response. Is refreshing?', isRefreshing(key) ? 'YES' : 'NO')

        if(isRefreshing(key)) {
            return cache[key].refreshingP
        }

        const p = retry(args)
            .catch(err => {
                invalidate(key)

                throw err
            })
            .then(retValue => {
                const response = getResponse(retValue)

                debug && console.info('Got immediate response. Ok?', response.ok ? 'YES' : 'NO')
                debug && console.info('  Return value:', retValue)
                if(!response.ok) {
                    invalidate(key)
                }

                return retValue
            })

        cache[key] = {
            stampMs: getStampMs(),
            refreshingP: null,
            p,
        }

        return p
    }

    const refreshResponse = (key, args) => {
        // not cached, can't refresh
        if(!isCached(key)) return
        debug && console.info('Refreshing.')

        // already refreshing
        if(cache[key].refreshingP) return

        const p = retry(args)
            .catch(err => {
                if(isCached(key)) {
                    cache[key].refreshingP = null
                }

                throw err
            })
            .then(retValue => {
                const response = getResponse(retValue)

                debug && console.info('Got refresh response. Ok?', response.ok ? 'YES' : 'NO')
                debug && console.info('  Return value:', retValue)
                if(response.ok) {
                    cache[key] = {
                        stampMs: getStampMs(),
                        refreshingP: null,
                        p,
                    }
                }
                else {
                    invalidate(key)
                }

                return retValue
            })

        cache[key].refreshingP = p
    }

    return (...args) => {
        const key = getCacheKey(args)
        debug && console.info('Running using key:', key)

        if(isCached(key)) {
            debug && console.info('is cached')
            // cached and fresh, just return the cached response
            if(isFresh(key)) {
                debug && console.info('is fresh')
                return cache[key].p
            }
            // if not returning stale responses or response is very stale, then fetch a new response
            else if(!enableStaleWhileRefresh || isVeryStale(key)) {
                debug && console.info('not fresh or stale disabled or very stale')
                return getImmediateResponse(key, args)
            }
            // it isn't fresh, but it also isn't "very stale", so we can return the stale value and refresh in the background
            else {
                debug && console.info('not fresh, returning stale')
                refreshResponse(key, args)

                return cache[key].p
            }
        }
        // not cached, so we need to fetch
        else {
            debug && console.info('not cached')
            return getImmediateResponse(key, args)
        }
    }
}
