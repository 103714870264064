import * as api from './api';
import * as actionTypes from './action-types';
export function newsletterSignupClear() {
  return {
    type: actionTypes.NEWSLETTER_SIGNUP_CLEAR
  };
}
export function newsletterSignup(tokenCache, _ref) {
  var name = _ref.name,
      email = _ref.email;
  return function (dispatch, getState) {
    dispatch(newsletterSignupRequest());
    var state = getState();
    return api.signup(tokenCache, state.config.message.apiUrl, {
      name: name,
      email: email
    }).then(function (data) {
      if (!data.success) {
        dispatch(newsletterSignupFailure(data.errors, data.paramErrors));
        return false;
      } else {
        dispatch(newsletterSignupSuccess());
        return true;
      }
    })["catch"](function (_) {
      dispatch(newsletterSignupFailure(['Something went wrong and we could not sign you up. Please try again.']));
      return false;
    });
  };
}

function newsletterSignupRequest() {
  return {
    type: actionTypes.NEWSLETTER_SIGNUP_REQUEST
  };
}

function newsletterSignupSuccess() {
  return {
    type: actionTypes.NEWSLETTER_SIGNUP_SUCCESS
  };
}

function newsletterSignupFailure(errors, paramErrors) {
  return {
    type: actionTypes.NEWSLETTER_SIGNUP_FAILURE,
    errors: errors,
    paramErrors: paramErrors
  };
}