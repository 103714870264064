import * as api from './api';
import * as actionTypes from './action-types';
export function login(tokenCache, params) {
  return function (dispatch, getState) {
    dispatch(loginRequest());
    var state = getState(); // resolve to true on success and false on failure

    return api.login(state.config.auth.apiUrl, params).then(function (data) {
      if (!data.success) {
        dispatch(loginFailure(data.errors, data.paramErrors));
        return false;
      } else {
        tokenCache.saveAuth({
          token: data.payload.token,
          refresh: data.payload.refresh,
          rememberMe: params.rememberMe
        });
        dispatch(loginSuccess());
        return true;
      }
    })["catch"](function (_) {
      dispatch(loginFailure(['Something went wrong and you could not be authenticated. Please try again.']));
      return false;
    });
  };
}

function loginRequest() {
  return {
    type: actionTypes.LOGIN_REQUEST
  };
}

function loginSuccess() {
  return {
    type: actionTypes.LOGIN_SUCCESS
  };
}

function loginFailure(errors, paramErrors) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    errors: errors,
    paramErrors: paramErrors
  };
}

export function loginClear() {
  return {
    type: actionTypes.LOGIN_CLEAR
  };
}