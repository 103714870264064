import { addNotificationSuccess } from '@@client/notification/actions';
import { signup } from '@@client/newsletter/api';
import * as api from './api';
import * as actionTypes from './action-types';
export function register(tokenCache, params) {
  return function (dispatch, getState) {
    dispatch(registerRequest());
    var state = getState(); // resolve to true on success and false on failure

    return api.register(tokenCache, state.config.auth.apiUrl, state.config.auth.portalId, params).then(function (data) {
      if (!data.success) {
        dispatch(registerFailure(data.errors, data.paramErrors));
        return false;
      } else {
        // if they also wanted to sign up for the newsletter
        if (params.newsletterSignup) {
          signup(tokenCache, state.config.message.apiUrl, {
            name: params.name,
            email: params.email
          }) // don't do anything on exceptions; it's not a big deal if the newsletter signup doesn't work
          ["catch"](function () {});
        }

        dispatch(addNotificationSuccess('You are registered! You just need to confirm your email address. Check your inbox for an email from us.', {
          autoDismiss: false
        }));
        dispatch(registerSuccess());
        return true;
      }
    })["catch"](function (_) {
      dispatch(registerFailure(['Something went wrong and you could not be registered. Please try again.']));
      return false;
    });
  };
}

function registerRequest() {
  return {
    type: actionTypes.REGISTER_REQUEST
  };
}

function registerSuccess() {
  return {
    type: actionTypes.REGISTER_SUCCESS
  };
}

function registerFailure(errors, paramErrors) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    errors: errors,
    paramErrors: paramErrors
  };
}

export function registerClear() {
  return {
    type: actionTypes.REGISTER_CLEAR
  };
}