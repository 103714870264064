export const hasProp = (name, obj) => Object.prototype.hasOwnProperty.call(obj, name)

// will return false if something other than an object, only true if empty and is an object
export const isEmptyObject = x => Object.keys(x).length === 0 && Object.getOwnPropertyNames(x).length === 0 && Object.getOwnPropertySymbols(x).length === 0 && Object.getPrototypeOf(x).constructor === Object

// remove any empty values. will not remove nested object values.
export const removeEmpty = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, val]) => Boolean(val)),
    )
}
