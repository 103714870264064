import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { decode } from 'jsonwebtoken';
import { get, isObject, liftA } from '@cullylarson/f';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
var authCookieNames = {
  details: 'wkr-auth-details',
  token: 'wkr-auth-token',
  refresh: 'wkr-auth-refresh'
};
export var isAuthenticated = function isAuthenticated(authSession) {
  return tokenFresh(get('tokenExpiresAt', undefined, authSession)) || tokenFresh(get('refreshExpiresAt', undefined, authSession));
};

var hasAny = function hasAny(itemsHad, itemsMustHave) {
  itemsHad = liftA(itemsHad);
  itemsMustHave = liftA(itemsMustHave);
  return itemsHad.reduce(function (acc, item) {
    return acc || itemsMustHave.includes(item);
  }, false);
};

export var isUser = function isUser(authSession) {
  var userRoles = ['user', 'administrator', 'employee'];
  var userGroups = ['users', 'administrators', 'employees'];
  var roles = get('roles', [], authSession);
  var groups = get('groups', [], authSession);
  return isAuthenticated(authSession) && (hasAny(roles, userRoles) || hasAny(groups, userGroups));
};
export var getAccountInfo = function getAccountInfo(authSession) {
  return authSession && isAuthenticated(authSession) ? authSession.account : null;
}; // cushion (in seconds) will be subtracted from expiresAt. This allows testing if a token expires "soon" and doing something
// like refreshing it, before it gets very close to expiring

export var tokenFresh = function tokenFresh(expiresAt) {
  var cushion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Boolean(expiresAt && new Date().getTime() < (expiresAt - cushion) * 1000);
};

var buildAuthSessionFromInfo = function buildAuthSessionFromInfo(_ref) {
  var claimsNamespace = _ref.claimsNamespace,
      token = _ref.token,
      refresh = _ref.refresh;
  // we can't verify the token (we could get the well-known keys, but they could be easily changed by the user), but we just need some info. from the token, nothing related to security
  var tokenDecoded = decode(token, {
    complete: true
  });
  var refreshDecoded = refresh ? decode(refresh, {
    complete: true
  }) : null;
  return {
    account: get(['payload', claimsNamespace, 'account'], undefined, tokenDecoded),
    tokenExpiresAt: get(['payload', 'exp'], undefined, tokenDecoded),
    refreshExpiresAt: get(['payload', 'exp'], undefined, refreshDecoded),
    permissions: get(['payload', claimsNamespace, 'permissions'], [], tokenDecoded).map(function (x) {
      return x.toLowerCase();
    }),
    groups: get(['payload', claimsNamespace, 'groups'], [], tokenDecoded).map(function (x) {
      return x.toLowerCase();
    }),
    roles: get(['payload', claimsNamespace, 'roles'], [], tokenDecoded).map(function (x) {
      return x.toLowerCase();
    }),
    token: token,
    refresh: refresh
  };
};

var retrieveAuthInfo = function retrieveAuthInfo(ctx) {
  var getDetails = function getDetails(cookies) {
    var detailsRaw = cookies[authCookieNames.details] || null;
    return !detailsRaw ? null : isObject(detailsRaw) ? detailsRaw : JSON.parse(detailsRaw);
  };

  var cookies = parseCookies(ctx);
  if (!cookies) return null;

  var info = _objectSpread(_objectSpread({}, getDetails(cookies)), {}, {
    token: cookies[authCookieNames.token] || null,
    refresh: cookies[authCookieNames.refresh] || null
  });

  return info.token ? info : null;
}; // if refresh is null, will attempt to retrieve it from current auth info
// if rememberMe is null, will attempt to retrieve it from current auth info


export var saveAuthSession = function saveAuthSession(claimsNamespace, _ref2) {
  var token = _ref2.token,
      _ref2$refresh = _ref2.refresh,
      refresh = _ref2$refresh === void 0 ? null : _ref2$refresh,
      _ref2$rememberMe = _ref2.rememberMe,
      rememberMe = _ref2$rememberMe === void 0 ? null : _ref2$rememberMe;
  var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var authInfo = retrieveAuthInfo(ctx);
  refresh = refresh || get('refresh', null, authInfo);
  rememberMe = rememberMe === null ? get('rememberMe', false, authInfo) : Boolean(rememberMe);

  var getCookieExpiresAt = function getCookieExpiresAt(refresh, token) {
    if (refresh) {
      var refreshDecoded = decode(refresh, {
        complete: true
      });
      var refreshExpiresAt = get(['payload', 'exp'], undefined, refreshDecoded);
      return new Date(refreshExpiresAt * 1000);
    } else if (token) {
      var tokenDecoded = decode(token, {
        complete: true
      });
      var tokenExpiresAt = get(['payload', 'exp'], undefined, tokenDecoded);
      return new Date(tokenExpiresAt * 1000);
    } else {
      return undefined; // session cookie
    }
  };

  var cookieExpiresAt = rememberMe ? getCookieExpiresAt(refresh, token) : undefined;
  var cookieValueInfo = JSON.stringify({
    claimsNamespace: claimsNamespace,
    rememberMe: rememberMe
  });
  setCookie(ctx, authCookieNames.details, cookieValueInfo, {
    expires: cookieExpiresAt,
    path: '/'
  });
  setCookie(ctx, authCookieNames.token, token, {
    expires: cookieExpiresAt,
    path: '/'
  });
  setCookie(ctx, authCookieNames.refresh, refresh, {
    expires: cookieExpiresAt,
    path: '/'
  });
  return getAuthSession(ctx);
};
export var getAuthSessionEmpty = function getAuthSessionEmpty() {
  return {
    account: null,
    tokenExpiresAt: null,
    refreshExpiresAt: null,
    token: null,
    refresh: null,
    permissions: [],
    groups: [],
    roles: []
  };
};
export var getAuthSession = function getAuthSession() {
  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var authInfo = retrieveAuthInfo(ctx); // we want to make sure to remove the auth session if it's expired, before returning it

  if (authInfo) {
    var authSession = buildAuthSessionFromInfo(authInfo);
    var token = get('token', undefined, authSession);
    var refresh = get('refresh', undefined, authSession);
    var tokenExpiresAt = get('tokenExpiresAt', undefined, authSession);
    var refreshExpiresAt = get('refreshExpiresAt', undefined, authSession);
    var tokenIsFresh = token && tokenFresh(tokenExpiresAt);
    var refreshIsFresh = refresh && tokenFresh(refreshExpiresAt); // if we have a token or a refresh but they're expired, then remove the auth session

    if ((token || refresh) && !tokenIsFresh && !refreshIsFresh) {
      return removeAuthSession(ctx);
    } // we still have a valid token or refresh
    else {
        return authSession;
      }
  } else {
    return getAuthSessionEmpty();
  }
};
export var removeAuthSession = function removeAuthSession() {
  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  Object.values(authCookieNames).forEach(function (x) {
    return destroyCookie(ctx, x, {
      path: '/'
    });
  });
  return getAuthSessionEmpty();
};