export var formatNumber = function formatNumber(x) {
  try {
    var xNum = Number(x);
    return isNaN(xNum) ? x : xNum.toLocaleString();
  } catch (_) {
    return x;
  }
};
var dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export var formatDollars = function formatDollars(x) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$includeZeroDecim = _ref.includeZeroDecimal,
      includeZeroDecimal = _ref$includeZeroDecim === void 0 ? true : _ref$includeZeroDecim;

  try {
    var xNum = Number(x);
    var result = isNaN(xNum) ? x : dollarFormatter.format(x);
    return includeZeroDecimal ? result : result.replace(/\.00$/, '');
  } catch (_) {
    return x;
  }
}; // copied from https://stackoverflow.com/a/11832950/1526406.  Apparently it isn't perfect, but good enough (would be like $0.01 off at worst)

export var roundTwoDigits = function roundTwoDigits(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
export var getEnding = function getEnding(howManyChars, x) {
  if (x === null || x === undefined || !x.toString()) return '';
  x = x.toString();
  return x.substr(-1 * howManyChars);
};