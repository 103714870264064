import {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {CSSTransition} from 'react-transition-group'
import {useRouter} from 'next/router'
import Link from 'next/link'
import {classnames} from '@@client/lib/classes'
import {getReturn} from '@@client/lib/routes'
import {useConfig} from '@@client/lib/hooks'
import {toggleBurger, closeBurger} from '@@client/burger/actions'
import Authenticated from '@@client/auth/Authenticated'
import NotAuthenticated from '@@client/auth/NotAuthenticated'
import NaturalSearchForm from '@@client/parts/NaturalSearchForm'
import CartIcon from '@@client/cart/CartIcon'
import CartText from '@@client/cart/CartText'
import NavBlueBar from './NavBlueBar'
import SlideOpen from './SlideOpen'
import LinksAboutUs from './footer/LinksAboutUs'
import LinksCustomerResources from './footer/LinksCustomerResources'
import LinksFindUsedParts from './footer/LinksFindUsedParts'
import LinksSellAVehicle from './footer/LinksSellAVehicle'
import LinksUserInformation from './footer/LinksUserInformation'
import LinksContactUs from './footer/LinksContactUs'

const mapStateToProps = ({burger}) => ({
    burger,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        toggleBurger,
        closeBurger,
    }, dispatch),
})

const NavMobile = ({burger, actions}) => {
    const config = useConfig()
    const router = useRouter()
    const burgerRef = useRef(null)
    const itemsRef = useRef(null)

    // close the burger on Esc
    useEffect(() => {
        const handleKeyDown = e => {
            if(event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
                // only close if burger, menu, or children are focused

                const focusedEl = document.activeElement
                if(!focusedEl) return

                if(
                    burgerRef.current === focusedEl
                    || itemsRef.current === focusedEl
                    || burgerRef.current.contains(focusedEl)
                    || itemsRef.current.contains(focusedEl)
                ) {
                    actions.closeBurger()
                    burgerRef.current.focus() // return focus to the burger
                }
            }
        }

        const on = () => document.addEventListener('keydown', handleKeyDown)
        const off = () => document.removeEventListener('keydown', handleKeyDown)

        on()

        return off
    }, [])

    // close burger on navigate
    useEffect(() => {
        const handleRouteChange = () => {
            actions.closeBurger()
        }

        const on = () => router.events.on('routeChangeComplete', handleRouteChange)
        const off = () => router.events.off('routeChangeComplete', handleRouteChange)

        on()

        return off
    }, [])

    const containerClassName = classnames([
        'at--hm',
        burger.isOpen ? 'is-open' : 'not-open',
    ])

    return (
        <>
            <div className={containerClassName}>
                <NavBlueBar className='mobile-nav-bar'>
                    <div className='inner'>
                        <CartIcon />
                        <div className='nav-sep' />
                        <button ref={burgerRef} className='burger m--no-btn' aria-label='Main Menu' aria-haspopup='true' aria-expanded={burger.isOpen ? 'true' : 'false'} aria-controls='header-burger-items' onClick={() => actions.toggleBurger()}><span className='burger-inner' /></button>
                    </div>
                </NavBlueBar>
                <CSSTransition
                    classNames='items-wrapper-transition'
                    in={burger.isOpen}
                    timeout={300}
                    appear
                    onEnter={(el) => {
                        el.style.height = el.scrollHeight + 'px'
                    }}
                    onEntered={(el) => {
                        el.style.height = 'auto'
                    }}
                    onExit={(el) => {
                        el.style.height = el.scrollHeight + 'px'
                    }}
                    onExiting={(el) => {
                        el.style.height = 0
                    }}
                    onExited={(el) => {
                        el.style.height = null
                    }}
                >
                    <div id='header-burger-items' className='items-wrapper' ref={itemsRef}>
                        <div className='items'>
                            <NaturalSearchForm />
                            <div className='item-sep' />
                            <ul className='primary'>
                                {config.hiring.enabled
                                    ? (<li className='special'><Link href='/contact/hiring'><a>B&amp;R is Hiring!</a></Link></li>)
                                    : null
                                }
                                <li><Link href='/engines'><a>Used Engines</a></Link></li>
                                <li><Link href='/transmissions'><a>Used Transmissions</a></Link></li>
                                <li><Link href='/parts/categories'><a>Popular Categories</a></Link></li>
                                <li><Link href='/brands'><a>Vehicle Brands</a></Link></li>
                                <li><Link href='/parts?part=rebuilder'><a>Rebuilder Vehicles</a></Link></li>
                                <NotAuthenticated>
                                    <li><Link href={`/account/login?r=${getReturn(router)}`}><a className='special'>Log In</a></Link></li>
                                </NotAuthenticated>
                                <Authenticated>
                                    <li><Link href='/account'><a className='special'>My Account</a></Link></li>
                                </Authenticated>
                                <li><CartText className='special' /></li>
                            </ul>
                            <div className='secondary'>
                                <SlideOpen id='header-about-us' title='About Us'>
                                    <LinksAboutUs />
                                </SlideOpen>
                                <SlideOpen id='header-user-information' title='User Information'>
                                    <LinksUserInformation />
                                </SlideOpen>
                                <SlideOpen id='header-contact-us' title='Contact Us'>
                                    <LinksContactUs chatButtonClassName='m--as-link' />
                                </SlideOpen>
                                <SlideOpen id='header-find-parts' title='Find Used Parts'>
                                    <LinksFindUsedParts />
                                </SlideOpen>
                                <SlideOpen id='header-general-info' title='General Information'>
                                    <LinksCustomerResources />
                                </SlideOpen>
                                <SlideOpen id='header-sell-vehicle' title='Sell a Vehicle'>
                                    <LinksSellAVehicle />
                                </SlideOpen>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .inner {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    .inner :global(.cart-icon),
                    .nav-sep,
                    .burger {
                        @media print {
                            opacity: 0;
                        }
                    }

                    .nav-sep {
                        height: 30px;
                        width: 1px;
                        background: white;
                        margin-left: 11px;
                    }

                    .burger {
                        box-sizing: content-box;
                        min-width: 30px;
                        padding: 15px 15px 30px 15px; /* setting padding-bottom works better than setting the height */
                    }

                    .burger-inner {
                        $burgerLineHeight: 2px;
                        $burgerLinePadding: 7px;

                        display: block;
                        position: relative;
                        height: $burgerLineHeight;
                        background: white;
                        width: 100%;
                        transition: all 300ms ease;

                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            height: $burgerLineHeight;
                            width: 100%;
                            background: white;
                            transition: all 300ms ease;
                        }

                        &::before {
                            top: calc($burgerLineHeight + $burgerLinePadding);
                        }

                        &::after {
                            top: calc(2 * ($burgerLineHeight + $burgerLinePadding));
                        }

                        .is-open & {
                            transform: rotate(45deg);
                            top: calc($burgerLineHeight + $burgerLinePadding);

                            &::before { top: 0; }

                            &::after {
                                top: 0;
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .items-wrapper {
                        display: none;
                        overflow: hidden;
                        user-select: none;
                        height: 0;
                        transition: all 300ms ease;
                        background: $c--blue--dark-gray;
                    }

                    .items-wrapper-transition-enter,
                    .items-wrapper-transition-enter-done,
                    .items-wrapper-transition-exit {
                        display: block;
                    }

                    .items {
                        padding: 35px 10px 25px 10px;

                        @media(--xxs) {
                            padding-top: 25px;
                        }
                    }

                    .item-sep {
                        width: 100%;
                        height: 1px;
                        background: $c--blue--light-gray;
                        margin: 20px 0;
                    }

                    ul.primary {
                        $liPaddingTb: 12px;

                        list-style: none;
                        margin: calc($liPaddingTb / 2) 0;
                        padding: 0;

                        li {
                            padding: $liPaddingTb 10px;

                            &:first-child { padding-top: 0; }
                        }

                        li :global(a) {
                            display: block;
                            color: white;
                            text-decoration: none;
                            font-size: 20px;
                        }

                        li :global(.special) {
                            color: $c--blue;
                        }
                    }

                    .secondary {
                        :global(li button.m--as-link),
                        :global(li a) {
                            padding-left: 20px;
                        }

                        :global(li:first-child button.m--as-link),
                        :global(li:first-child a) {
                            padding-top: 0;
                        }

                        :global(li:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMobile)
