import Link from 'next/link'
import {useConfig} from '@@client/lib/hooks'
import NaturalSearchForm from '@@client/parts/NaturalSearchForm'
import NavBlueBar from './NavBlueBar'

export default function NavPrimary() {
    const config = useConfig()

    return (
        <>
            <NavBlueBar label='Primary navigation' className='at--hd'>
                <div className='container'>
                    <ul>
                        {config.hiring.enabled
                            ? (<li className='special'><Link href='/contact/hiring'><a>B&amp;R is Hiring!</a></Link></li>)
                            : null
                        }
                        <li><Link href='/engines'><a>Used Engines</a></Link></li>
                        <li><Link href='/transmissions'><a>Used Transmissions</a></Link></li>
                        <li><Link href='/parts/categories'><a>Popular Categories</a></Link></li>
                        {config.hiring.enabled
                            ? null
                            : (<li><Link href='/brands'><a>Vehicle Brands</a></Link></li>)
                        }
                        <li><Link href='/parts?part=rebuilder'><a>Rebuilder Vehicles</a></Link></li>
                    </ul>
                    <NaturalSearchForm />
                </div>
            </NavBlueBar>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    $nav-wide-min: 1525px;
                    $nav-5-items-min: 1328px;
                    $nav-4-items-min: 1175px;
                    $nav-3-items-min: 1039px;

                    @custom-media --nav-wide (min-width: $nav-wide-min);
                    @custom-media --nav-5-items (min-width: $nav-5-items-min);
                    @custom-media --nav-4-items (min-width: $nav-4-items-min);
                    @custom-media --nav-3-items (min-width: $nav-3-items-min);

                    .container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }

                    li {
                        $dividerHeight: 14px;

                        position: relative;
                        display: inline-block;

                        /* need to hide some menu items as the screen narrows */
                        &:last-child {
                            display: none;
                            @media(--nav-5-items) { display: inline-block; }

                            &::after {
                                display: none;
                            }
                        }

                        /* need to hide some menu items as the screen narrows */
                        &:nth-last-child(2) {
                            display: none;
                            @media(--nav-4-items) { display: inline-block; }

                            &:after {
                                display: none;
                                @media(--nav-5-items) { display: block; }
                            }
                        }

                        /* need to hide some menu items as the screen narrows */
                        &:nth-last-child(3) {
                            display: none;
                            @media(--nav-3-items) { display: inline-block; }

                            &:after {
                                display: none;
                                @media(--nav-4-items) { display: block; }
                            }
                        }

                        &:nth-last-child(4) {
                            &:after {
                                display: none;
                                @media(--nav-3-items) { display: block; }
                            }
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: calc(50% - ($dividerHeight / 2));
                            height: $dividerHeight;
                            width: 1px;
                            background: white;
                        }

                        a, a:visited {
                            display: block;
                            color: white;
                            padding: 10px 17px;
                            text-decoration: none;

                            @media(--nav-wide) {
                                padding: 10px 28px;
                            }
                        }

                        &.special a, a:hover, a:active {
                            color: $c--yellow;
                        }
                    }

                    .container :global(form) {
                        width: 200px;

                        @media(--nav-wide) {
                            width: 280px;
                        }
                    }
                `}
            </style>
        </>
    )
}
