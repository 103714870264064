import * as actionTypes from './action-types';
export function toggleBurger() {
  return {
    type: actionTypes.TOGGLE_BURGER
  };
}
export function openBurger() {
  return {
    type: actionTypes.OPEN_BURGER
  };
}
export function closeBurger() {
  return {
    type: actionTypes.CLOSE_BURGER
  };
}