import { get } from '@cullylarson/f';
import { decode } from 'jsonwebtoken';
import { tokenFresh } from '@@client/lib/auth';
import { addNotificationError } from '@@client/notification/actions';
import * as actionTypes from './action-types';
import * as api from './api';

function logoutRequest() {
  return {
    type: actionTypes.LOGOUT_REQUEST
  };
}

function logoutSuccess() {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
}

function logoutFailure(errors) {
  return {
    type: actionTypes.LOGOUT_FAILURE,
    errors: errors
  };
}

export function logout(tokenCache) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$revoke = _ref.revoke,
      revoke = _ref$revoke === void 0 ? true : _ref$revoke,
      _ref$notifyOnRevokeFa = _ref.notifyOnRevokeFailure,
      notifyOnRevokeFailure = _ref$notifyOnRevokeFa === void 0 ? true : _ref$notifyOnRevokeFa;

  return function (dispatch, getState) {
    dispatch(logoutRequest());
    var authSession = tokenCache.getAuth();
    var state = getState();
    var refreshDecoded = authSession.refresh ? decode(authSession.refresh) : undefined;
    var refreshSessionId = get([state.config.auth.claimsNamespace, 'sessionId'], undefined, refreshDecoded);

    var completeLogout = function completeLogout(errors) {
      // if it's a 404, then the session no longer exists. Just logout as normal
      var isPageNotFound = errors && errors.length && errors.filter(function (x) {
        return x && x.code && x.code === 'page-not-found';
      }).length > 0;

      if (!isPageNotFound && errors && errors.length) {
        dispatch(logoutFailure(errors));

        if (notifyOnRevokeFailure) {
          dispatch(addNotificationError('Something went wrong and you could not be logged out.'));
        }

        return Promise.resolve(false);
      } else {
        tokenCache.removeAuth();
        dispatch(logoutSuccess());
        return Promise.resolve(true);
      }
    }; // no refresh session id, no way to revoke the refresh token so just complete


    if (!refreshSessionId) {
      return completeLogout();
    } // refresh token is expired, can't refresh, no need to revoke token so just complete
    else if (!tokenFresh(authSession.refreshExpiresAt, 60)) {
        return completeLogout();
      } else if (tokenCache && revoke) {
        return api.revokeSession(tokenCache, state.config.auth.apiUrl, refreshSessionId)["catch"](function (_) {
          return completeLogout(['Something went wrong and you could not be logged out. Please try again.']);
        }).then(function (data) {
          if (data.errors) {
            return completeLogout(data.errors);
          } else {
            return completeLogout();
          }
        });
      } else {
        return completeLogout();
      }
  };
}