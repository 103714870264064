import {useRef, useEffect, useState, useContext} from 'react'
import {useSelector} from 'react-redux'
import DepsContext from '@@client/app/DepsContext'
import {isUser, getAccountInfo} from '@@client/lib/auth'

export const useIsUser = () => {
    useSelector(state => state.account.login.loginIdentifier) // this is just here so that we'll re-render if login changes
    const tokenCache = useTokenCache()
    const authSession = tokenCache.getAuth()
    const [localIsUser, setLocalIsUser] = useState(isUser(authSession))

    useEffect(() => {
        setLocalIsUser(isUser(authSession))
    }, [authSession.token])

    return localIsUser
}

export const useAccountInfo = () => {
    useSelector(state => state.account.login.loginIdentifier) // this is just here so that we'll re-render if login changes
    const tokenCache = useTokenCache()
    const authSession = tokenCache.getAuth()
    const [accountInfo, setAccountInfo] = useState(getAccountInfo(authSession))

    useEffect(() => {
        setAccountInfo(getAccountInfo(authSession))
    }, [authSession.token])

    return accountInfo
}

// basically a componentDidUpdate hook
export const useUpdate = (onChange, watchUs) => {
    const hasRun = useRef(false)

    useEffect(() => {
        if(!hasRun.current) {
            hasRun.current = true
        }
        else {
            onChange(watchUs)
        }
    }, watchUs)
}

export const useInterval = (callback, delay, {
    watchForChanges = [delay],
    // if true, will run before starting the interval. if false, will run for
    // the first time after `delay` milliseconds.
    runOnStartup = false,
} = {}) => {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if(runOnStartup) {
            callback()
        }
    }, [])

    // Set up the interval.
    useEffect(() => {
        if(!delay) return

        const id = setInterval(savedCallback.current, delay)

        return () => clearInterval(id)
    }, [watchForChanges])
}

export const useConfig = () => {
    return useSelector(state => state.config)
}

export const useChat = () => {
    return useSelector(state => state.chat)
}

// triggerOn can be:
// - any -- when any image is loaded
// - first -- when the first image is loaded
// - all -- when all the images are loaded
export const usePreloadImages = (imageUrls, triggerOn = 'first') => {
    const numToLoad = useRef(imageUrls.length)
    const numLoaded = useRef(0)
    const [isLoaded, setIsLoaded] = useState(false)

    const handleOnLoad = (isFirst) => e => {
        numLoaded.current++

        if(triggerOn === 'first' && isFirst) {
            setIsLoaded(true)
        }
        else if(triggerOn === 'all' && numLoaded.current === numToLoad.current) {
            setIsLoaded(true)
        }
        else if(triggerOn === 'any') {
            setIsLoaded(true)
        }
    }

    // if this is triggered a lot, this could potentially be bad, since it will create new image objects on every trigger
    useEffect(() => {
        const imgs = imageUrls.map((url, i) => {
            const img = new Image()
            img.src = url
            img.onload = handleOnLoad(i === 0)

            return img
        })

        return () => {
            imgs.forEach(img => (img.onload = null))
        }
    }, [imageUrls])

    return isLoaded
}

export const useTokenCache = () => {
    const {tokenCache} = useDeps()

    return tokenCache
}

export const useDeps = () => {
    return useContext(DepsContext)
}

export const useScript = (src, {
    isAsync = true,
} = {}) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = isAsync
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [src])
}
