import { addNotificationSuccess } from '@@client/notification/actions';
import * as api from './api';
import * as actionTypes from './action-types';
export function recoverPassword(tokenCache, params) {
  return function (dispatch, getState) {
    dispatch(recoverPasswordRequest());
    var state = getState(); // resolve to true on success and false on failure

    return api.recoverPassword(tokenCache, state.config.auth.apiUrl, state.config.auth.portalId, params).then(function (data) {
      if (!data.success) {
        dispatch(recoverPasswordFailure(data.errors, data.paramErrors));
        return false;
      } else {
        dispatch(addNotificationSuccess('Check your inbox, you will get an email from us with a link to change your password.', {
          autoDismiss: false
        }));
        dispatch(recoverPasswordSuccess());
        return true;
      }
    })["catch"](function (_) {
      dispatch(recoverPasswordFailure(['Something went wrong and your password recovery could not be started. Please try again.']));
      return false;
    });
  };
}

function recoverPasswordRequest() {
  return {
    type: actionTypes.RECOVER_PASSWORD_REQUEST
  };
}

function recoverPasswordSuccess() {
  return {
    type: actionTypes.RECOVER_PASSWORD_SUCCESS
  };
}

function recoverPasswordFailure(errors, paramErrors) {
  return {
    type: actionTypes.RECOVER_PASSWORD_FAILURE,
    errors: errors,
    paramErrors: paramErrors
  };
}

export function recoverPasswordClear() {
  return {
    type: actionTypes.RECOVER_PASSWORD_CLEAR
  };
}