import {useEffect, useCallback} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {sendEvent} from '@@client/lib/gtm'
import {idFromPrefix} from '@@client/lib/forms'
import {useTokenCache} from '@@client/lib/hooks'
import {register as registerAction, registerClear} from '@@client/account/register/actions'
import Messages from '@@client/components/Messages'
import Modal from '@@client/components/Modal'
import PrivacyTermsBrief from '@@client/components/PrivacyTermsBrief'
import RegisterForm from '@@client/account/register/RegisterForm'

const mapStateToProps = ({account: {register}}) => ({
    register,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        register: registerAction,
        registerClear,
    }, dispatch),
})

const RegisterModal = ({actions, register, isOpen, onClose, onLoginClick}) => {
    useEffect(() => { actions.registerClear() }, [isOpen])

    const handleSubmit = useCallback((params) => {
        // already logging in
        if(register.doing) return

        actions.register(tokenCache, params)
            .then(success => {
                if(success) {
                    sendEvent('register-account')

                    onClose()
                }
            })
    }, [register])

    const tokenCache = useTokenCache()

    const footer = (
        <>
            Already have an account? <button className='m--as-link' onClick={onLoginClick}>Login</button>
        </>
    )

    const idPrefix = 'register-modal'

    return (
        <Modal
            initialFocus={'#' + idFromPrefix(idPrefix, 'name')}
            isOpen={isOpen}
            onClose={onClose}
            title='Create an account'
            subtitle='Register to see your saved parts, vehicles, and more.'
            footer={footer}
        >
            <div className='container'>
                <Messages error={register.errors} />

                <RegisterForm
                    idPrefix={idPrefix}
                    onSubmit={handleSubmit}
                    paramErrors={register.paramErrors}
                    disabled={register.doing}
                />

                <PrivacyTermsBrief className='m--center' />
            </div>

            <style jsx>
                {`
                    @import '@css/variables.css';

                    .container :global(.actions) {
                        text-align: center;
                    }
                `}
            </style>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal)
