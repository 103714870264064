import {compose} from '@cullylarson/f'
import {format as dateFormat} from 'date-fns'

export function isDev() {
    return (
        process.env.NODE_ENV === 'development'
        || process.env.NODE_ENV === 'staging'
        || process.env.WKR_ENV === 'development'
        || process.env.WKR_ENV === 'staging'
    )
}

export function isTest() {
    return process.env.NODE_ENV === 'test'
}

export function isEmpty(x) {
    return x === '' || x === false || Number.isNaN(x) || x === null || x === undefined
}

export const notEmpty = x => !isEmpty(x)

export const isBool = x => x === true || x === false

export function debounce(f, ms) {
    let timer

    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            f.apply(null, args)
        }, ms)
    }
}

// used to pass events into a child component
export function TriggerManager() {
    let cb = null

    return {
        trigger: (...args) => {
            if(cb) cb.apply(null, args)
        },
        setCallback: f => { cb = f },
    }
}

// get an ending if number of items is other than 1
export function endPlural(numItems, ending = 's') {
    numItems = Array.isArray(numItems) ? numItems.length : numItems

    return numItems === 1 ? '' : ending
}

// get an ending if number of items is exactly 1
export function endSingular(numItems, ending = 's') {
    numItems = Array.isArray(numItems) ? numItems.length : numItems

    return numItems === 1 ? ending : ''
}

export function buildPrefetchUrls(urls) {
    if(!urls || !urls.length) return []

    return [...new Set(
        urls
            .filter(Boolean)
            .map(x => new URL(x))
            .map(x => `${x.protocol}//${x.host}`),
    )]
}

const removeNonNumber = x => x.replace(/[^0-9]/g, '')

export function formatPhone(phoneStr) {
    if(!phoneStr || !phoneStr.trim || !phoneStr.trim()) return ''

    const formatSeven = x => x.substring(0, 3) + '-' + x.substring(3)
    const formatArea = x => '(' + x + ')'

    const format = x => {
        if(x.length === 7) {
            return formatSeven(x)
        }
        else if(x.length === 10) {
            return [
                formatArea(x.substring(0, 3)),
                formatSeven(x.substring(3)),
            ].join(' ')
        }
        else if(x.length === 11) {
            return [
                x[0],
                x.substring(1, 4),
                x.substring(4, 7),
                x.substring(7),
            ].join('-')
        }
        else {
            return x
        }
    }

    return compose(
        format,
        removeNonNumber,
    )(phoneStr)
}

// produces a number like: +15554441212
export function formatPhoneTel(phoneStr) {
    if(!phoneStr || !phoneStr.trim || !phoneStr.trim()) return ''

    const format = x => {
        if(x.length === 10) {
            return '+1' + x
        }
        else if(x.length === 11) {
            return '+' + x
        }
        else {
            return x
        }
    }

    return compose(
        format,
        removeNonNumber,
    )(phoneStr)
}

// 0123456789 -> W01-2345-6789
export const formatOrderNumber = orderNumber => {
    if(!orderNumber) return ''

    return 'W'
        + orderNumber.substring(0, 2)
        + '-'
        + orderNumber.substring(2, 6)
        + '-'
        + orderNumber.substring(6)
}

export const formatApiDate = dateStr => dateFormat(new Date(dateStr), 'M/d/yyyy')

// a regex string, used in URL paths to match a UUID
export const isUuid = x => /[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+-[a-z0-9]+/.test(x)
