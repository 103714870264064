import Head from 'next/head'
import {useConfig} from '@@client/lib/hooks'
import Footer from './Footer'
import {isDev} from '@@client/lib/util'
import Notifications from '@@client/notification/Notifications'
import {NextSeo} from 'next-seo'
import Header from './Header'

const renderTitle = (bits) => bits
    .filter(x => Boolean(x))
    .join(' | ')

export default function Layout({
    children,
    title,
    description = 'Quality used car & truck parts, engines, and transmissions from our vehicle salvage yards. Do it yourself for less with recycled auto parts—the B&R way!',
    keywords = 'pick n pull, u pull it, pick a part, discount auto body parts, pick and pull, junkyard, pick your part, u pull it auto parts, junk yard, auto parts network, online junkyard, barely used auto parts, pick n pay, import auto salvage, wrecking yard, auto wrecker, pick and pay',
    images = [
        {
            url: '/images/logos/autowrecking-com-share.png',
            alt: 'B&R Autowrecking.com',
        },
    ],
    type = 'website',
    article = {}, // open graph article details
}) {
    title = renderTitle([title, 'B&R Autowrecking'])

    const config = useConfig()

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                keywords={keywords}
                nofollow={isDev()}
                noindex={isDev()}
                openGraph={{
                    type,
                    title,
                    description,
                    images,
                    article,
                }}
            />
            <Head>
                <meta name='viewport' content='initial-scale=1.0, width=device-width' />

                <link rel='dns-prefetch' href='https://fonts.googleapis.com' />
                <link rel='dns-prefetch' href='https://fonts.gstatic.com' />

                <link rel='preload' as='style' href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i&display=swap' />
                <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i&display=swap'/>

                {
                    config.tracking.gaTrackingId
                        ? (
                            <>
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                            })(window,document,'script','dataLayer','${config.tracking.gaTrackingId}');`,
                                    }}
                                />
                                <noscript
                                    dangerouslySetInnerHTML={{
                                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.tracking.gaTrackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                                    }}
                                />
                            </>
                        )
                        : null
                }

                {
                    config.tracking.facebookPixelId
                        ? (
                            <>
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                            !function(f,b,e,v,n,t,s)
                                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                            n.queue=[];t=b.createElement(e);t.async=!0;
                                            t.src=v;s=b.getElementsByTagName(e)[0];
                                            s.parentNode.insertBefore(t,s)}(window,document,'script',
                                            'https://connect.facebook.net/en_US/fbevents.js');
                                            fbq('init', '${config.tracking.facebookPixelId}');
                                            fbq('track', 'PageView');
                                      `,
                                    }}
                                />
                            </>
                        )
                        : null
                }

                <script async type='text/javascript' src='https://home-c31.incontact.com/inContact/ChatClient/js/embed.min.js'></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.addEventListener('load', () => {
                            icPatronChat.init({serverHost:'https://home-c31.incontact.com',bus_no:4598544,poc:'60b0f349-5798-4aaf-a39e-001c7df1e733',params:['FirstName','Last Name','first.last@company.com','555-555-5555']});
                        })
`,
                    }}
                />

                {/* prefetch for later include of the Cubano font */}
                <link rel='dns-prefetch' href='https://use.typekit.net' />
                <link rel='dns-prefetch' href='https://p.typekit.net' />

                {config.dnsPrefetchUrls.map(x => <link key={x} rel='dns-prefetch' href={x} />)}

                <link rel='manifest' href='/manifest.json' />

                <meta name='application-name' content='B&R Autowrecking' />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content='PWA App' />
                <meta name='description' content='Quality used car & truck parts, engines, and transmissions from our vehicle salvage yards.' />
                <meta name='format-detection' content='telephone=no' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='theme-color' content='#000000' />

                <link rel='apple-touch-icon' sizes='57x57' href='/images/favicons/apple-touch-icon-57x57.png' />
                <link rel='apple-touch-icon' sizes='60x60' href='/images/favicons/apple-touch-icon-60x60.png' />
                <link rel='apple-touch-icon' sizes='72x72' href='/images/favicons/apple-touch-icon-72x72.png' />
                <link rel='apple-touch-icon' sizes='76x76' href='/images/favicons/apple-touch-icon-76x76.png' />
                <link rel='apple-touch-icon' sizes='114x114' href='/images/favicons/apple-touch-icon-114x114.png' />
                <link rel='apple-touch-icon' sizes='120x120' href='/images/favicons/apple-touch-icon-120x120.png' />
                <link rel='apple-touch-icon' sizes='144x144' href='/images/favicons/apple-touch-icon-144x144.png' />
                <link rel='apple-touch-icon' sizes='152x152' href='/images/favicons/apple-touch-icon-152x152.png' />
                <link rel='icon' type='image/png' href='/images/favicons/favicon-16x16.png' sizes='16x16' />
                <link rel='icon' type='image/png' href='/images/favicons/favicon-32x32.png' sizes='32x32' />
                <link rel='icon' type='image/png' href='/images/favicons/favicon-96x96.png' sizes='96x96' />
                <link rel='icon' type='image/png' href='/images/favicons/favicon-128x128.png' sizes='128x128' />
                <link rel='icon' type='image/png' href='/images/favicons/favicon-196x196.png' sizes='196x196' />
                <meta name='msapplication-tap-highlight' content='no' />
                <meta name='msapplication-TileColor' content='#FFFFFF' />
                <meta name='msapplication-TileImage' content='/images/favicons/mstile-144x144.png' />
                <meta name='msapplication-square70x70logo' content='/images/favicons/mstile-70x70.png' />
                <meta name='msapplication-square150x150logo' content='/images/favicons/mstile-150x150.png' />
                <meta name='msapplication-wide310x150logo' content='/images/favicons/mstile-310x150.png' />
                <meta name='msapplication-square310x310logo' content='/images/favicons/mstile-310x310.png' />
            </Head>
            <Notifications />
            <Header />
            <main>
                {children}
            </main>
            <Footer />
        </>
    )
}
