import urlJoin from 'url-join';
import { bearer, responseData } from '@@client/lib/request';
export function signup(tokenCache, apiUrl, _ref) {
  var name = _ref.name,
      email = _ref.email;
  var url = urlJoin(apiUrl, '/api/v1/newsletter/subscribe');
  var query = {
    name: name,
    email: email
  };
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'post',
      body: JSON.stringify(query),
      headers: {
        'Authorization': bearer(token),
        'Content-Type': 'application/json'
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true
      };
    } else if (response.status === 400) {
      return {
        success: false,
        errors: data.errors.length ? data.errors : ['There were some problems with the values provided. Please see the error messages below.'],
        paramErrors: data.paramErrors
      };
    } else {
      return {
        success: false,
        errors: data.errors,
        paramErrors: []
      };
    }
  });
}