import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose, map, filter, set } from '@cullylarson/f';
import { cartEmpty, getCart, hasItem, isMakeOffer, getMakeOfferPrice, getSelectedWarrantyInfo, getDefaultWarrantyInfo, getSelectedWarrantyId, getItemCost, getNonDefaultWarrantyInfos, getWarrantyCost, getWarrantyInfoById } from './cart-repo';
/*
 * Since the shopping cart is only on the client, it presents opportunities for nexjt to complain
 * about the server rendered version of the site being different from the client rendered version
 * (e.g. Prop `className` did not match). A solution is to set the the cart in useEffect.
 * Need to set this in a useEffect because the cart is stored on the client, in localStorage. By
 * setting it in a useEffect, the intial server and client renders will be the same and nextjs
 * won't throw an error.
 */

var uppercaseFirstLetter = function uppercaseFirstLetter(x) {
  return x.charAt(0).toUpperCase() + x.slice(1);
};

var endS = function endS(num) {
  var pluralChar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 's';
  return num === 1 ? '' : pluralChar;
};

export var useInCart = function useInCart(inventoryId) {
  var _useCart = useCart(),
      items = _useCart.items;

  var _useState = useState(false),
      inCart = _useState[0],
      setInCart = _useState[1];

  useEffect(function () {
    setInCart(hasItem(items, inventoryId));
  }, [inventoryId, items]);
  return inCart;
};
export var useCartLastSaved = function useCartLastSaved() {
  return useSelector(function (state) {
    return state.cart.lastSaved;
  });
};
export var useCart = function useCart() {
  var cartLastSaved = useCartLastSaved();

  var _useState2 = useState(cartEmpty),
      localCart = _useState2[0],
      setLocalCart = _useState2[1];

  useEffect(function () {
    var cart = getCart();
    setLocalCart(cart);
  }, [cartLastSaved]);
  return localCart;
};
export var useIsMakeOffer = function useIsMakeOffer(inventoryId) {
  var cart = useCart();
  return isMakeOffer(inventoryId, cart);
};
export var useItemPrice = function useItemPrice(inventoryId) {
  var cart = useCart();
  return getItemCost(inventoryId, cart);
};
export var useMakeOfferPrice = function useMakeOfferPrice(inventoryId) {
  var cart = useCart();
  return getMakeOfferPrice(inventoryId, cart);
};
export var useWarrantyOptions = function useWarrantyOptions() {
  var warrantyEntities = useSelector(function (state) {
    return state.warranty.list.entities;
  });
  return useMemo(function () {
    return warrantyEntities.map(function (x) {
      return _objectSpread(_objectSpread({}, x), {}, {
        period: "".concat(x.periodValue, " ").concat(uppercaseFirstLetter(x.periodUnit)),
        periodPlural: "".concat(x.periodValue, " ").concat(uppercaseFirstLetter(x.periodUnit)).concat(endS(x.periodValue)),
        periodHyphenated: "".concat(x.periodValue, "-").concat(uppercaseFirstLetter(x.periodUnit))
      });
    }) // key them by their ids
    .reduce(function (acc, x) {
      return set(x.id, x, acc);
    }, {});
  }, [warrantyEntities]);
};
export var useSelectedWarrantyInfo = function useSelectedWarrantyInfo(inventoryId) {
  var cart = useCart();
  var warrantyOptions = useWarrantyOptions();
  return getSelectedWarrantyInfo(warrantyOptions, cart, inventoryId);
};
export var useSelectedWarrantyId = function useSelectedWarrantyId(inventoryId) {
  var cart = useCart();
  var warrantyOptions = useWarrantyOptions();
  return getSelectedWarrantyId(warrantyOptions, cart, inventoryId);
};
export var useDefaultWarrantyInfo = function useDefaultWarrantyInfo() {
  var warrantyOptions = useWarrantyOptions();
  return getDefaultWarrantyInfo(warrantyOptions);
};
export var useNonDefaultWarrantyInfos = function useNonDefaultWarrantyInfos() {
  var warrantyOptions = useWarrantyOptions();
  return getNonDefaultWarrantyInfos(warrantyOptions);
};
export var useCartSummary = function useCartSummary() {
  var cart = useCart();
  var warrantyOptions = useWarrantyOptions();
  return useMemo(function () {
    var items = cart.items;
    var warranties = cart.warranties; // only has items over $0

    var coreCosts = Object.values(items).filter(function (x) {
      return Boolean(x.corePrice);
    }).map(function (x) {
      return x.corePrice;
    }); // only has items over $0

    var warrantyCosts = compose(Object.values, filter(Boolean), map(function (warrantyId, inventoryId) {
      var itemCost = getItemCost(inventoryId, cart);
      var warrantyInfo = getWarrantyInfoById(warrantyId, warrantyOptions);
      return getWarrantyCost(warrantyInfo, itemCost);
    }))(warranties);
    var itemTotal = Object.keys(items).map(function (inventoryId) {
      return getItemCost(inventoryId, cart);
    }).reduce(function (acc, x) {
      return acc + x;
    }, 0);
    var coreTotal = coreCosts.reduce(function (acc, x) {
      return acc + x;
    }, 0);
    var warrantyTotal = warrantyCosts.reduce(function (acc, x) {
      return acc + x;
    }, 0);
    return {
      items: {
        numItems: Object.values(items).length,
        total: itemTotal
      },
      cores: {
        numItems: coreCosts.length,
        total: coreTotal
      },
      extendedWarranties: {
        numItems: warrantyCosts.length,
        total: warrantyTotal
      },
      grandTotal: itemTotal + warrantyTotal + coreTotal
    };
  }, [cart.items, cart.offers, cart.warranties]);
};