import urlJoin from 'url-join';
import fetch from 'isomorphic-unfetch';
import { responseData } from '@@client/lib/request';
export function login(apiUrl, _ref) {
  var email = _ref.email,
      password = _ref.password,
      rememberMe = _ref.rememberMe;
  var query = {
    email: email,
    password: password,
    rememberMe: rememberMe,
    refresh: true
  };
  return fetch(urlJoin(apiUrl, '/api/v1/account/authenticate'), {
    method: 'post',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        payload: data
      };
    } else if (response.status === 400) {
      return {
        success: false,
        errors: data.errors.length ? data.errors : ['There were some problems with the values provided. Please see the error messages below.'],
        paramErrors: data.paramErrors
      };
    } else {
      return {
        success: false,
        errors: data.errors,
        paramErrors: []
      };
    }
  });
}