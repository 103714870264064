import { isObject } from '@cullylarson/f'; // can provide a string, an array, or individual string arguments. e.g. classnames('my-class'), classnames(['my-class-1', 'my-class-2']), classnames('my-class-1', 'my-class-2')

export function classnames() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  if (!args.length) return '';
  var x = args.length === 1 ? args[0] : args;
  return !isObject(x) ? x : x.filter(function (x) {
    return !!x;
  }).join(' ');
}