import Link from 'next/link'

export default function LinksFindUsedParts() {
    return (
        <ul>
            <li><Link href='/engines'><a>Used Engines</a></Link></li>
            <li><Link href='/transmissions'><a>Used Transmissions</a></Link></li>
            <li><Link href='/parts/categories'><a>Part Categories</a></Link></li>
            <li><Link href='/brands'><a>Vehicle Brands</a></Link></li>
            <li><Link href='/parts?part=rebuilder'><a>Rebuilder Vehicles</a></Link></li>
        </ul>
    )
}
