export var preventDefault = function preventDefault(f) {
  return function (e) {
    e.preventDefault();
    if (f) f(e);
  };
};
export var stopPropagation = function stopPropagation(f) {
  return function (e) {
    e.stopPropagation();
    if (f) f(e);
  };
};