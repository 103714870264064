import Link from 'next/link'
import {useDeps} from '@@client/lib/hooks'
import {get} from '@cullylarson/f'

export default function LinksCustomerResources() {
    const {generalContent} = useDeps()

    const creditCardAuthorizationPdf = get(['creditCardAuthorizationPdf', 'url'], '', generalContent)
    const creditApplicationPdfUrl = get(['creditApplicationPdf', 'url'], '', generalContent)

    return (
        <ul>
            <li><Link href='/resources/shipping-and-returns'><a>Shipping & Returns</a></Link></li>
            <li><Link href='/resources/warranties'><a>Warranties</a></Link></li>
            <li><Link href='/resources/faqs'><a>FAQs</a></Link></li>
            <li><Link href='/legal/privacy'><a>Privacy Policy</a></Link></li>
            <li><Link href='/legal/terms'><a>Terms & Conditions</a></Link></li>
            <li><Link href='/resources/trim-codes'><a>Trim & Paint Color Codes</a></Link></li>
            <li><Link href='/resources/cut-sheets'><a>Cut Sheets</a></Link></li>
            <li><Link href='/resources/tax-forms'><a>Tax Forms</a></Link></li>
            <li><Link href='/resources/rebuilders'><a>Rebuilder Regulations</a></Link></li>
            <li><a href={creditCardAuthorizationPdf}>Credit Card Authorization</a></li>
            <li><a href={creditApplicationPdfUrl}>Credit Application</a></li>
        </ul>
    )
}
