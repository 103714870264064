// gets a return path. if current path is not defined or is in blacklist, will return defaultPath
export function getReturn(router) {
  var defaultPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
  var blacklist = ['/account/login', '/account/register', '/account/recover'];

  if (!router || !router.pathname || blacklist.includes(router.pathname)) {
    return defaultPath;
  } else {
    return router.pathname;
  }
}