import {get, reduce, filter} from '@cullylarson/f'
import {isServer} from '@@client/lib/nextjs'
import {hasProp} from '@@client/lib/objs'
import {roundTwoDigits} from '@@client/lib/numbers'
import {hasRetailPrice} from '@@client/parts/parts-helper'

const cartStorageKey = 'wkr:cart'
const cartVersion = 1 // if the structure of the cart ever changes, or even the structure of part objects, increment this value

const oneHour = 3600000 // 3600000ms = 1h

export const cartEmpty = {
    version: cartVersion,
    // part data entities indexed by inventory ids
    items: {},
    // dollar ammounts indexed by inventory ids
    offers: {},
    // warranty ids indexed by inventory ids
    warranties: {},
    recentlyViewed: [],
    // timestamp indexed by inventory ids. stamp of when an item was last updated from the API
    itemsLastUpdated: {},
}

export const clearCart = () => saveCart(cartEmpty)

export const getCart = () => {
    if(isServer()) return cartEmpty

    const cartRaw = localStorage.getItem(cartStorageKey)

    const cart = cartRaw
        ? JSON.parse(cartRaw)
        : cartEmpty

    // we don't want to keep around an old cart version
    return cart.version === cartVersion
        ? cart
        : cartEmpty
}

export const saveCart = (cart) => {
    if(isServer()) return

    const cartFinal = {
        ...getCart(),
        ...cart,
    }

    localStorage.setItem(cartStorageKey, JSON.stringify(cartFinal))
}

export const getItemNames = (cart, inventoryIds) => {
    return inventoryIds
        .map(id => cart.items[id] || null)
        .filter(Boolean)
        .map(partInfo => `${partInfo.modelYear} ${partInfo.makeName} ${partInfo.carlineName} ${partInfo.partTypeName}`)
}

export const hasItem = (items, inventoryId) => hasProp(inventoryId, items)

export const getSelectedWarrantyId = (warrantyOptions, cart, inventoryId) => {
    const warrantyIdsByInventoryId = cart.warranties

    // no warranty option for this item
    if(!hasProp(inventoryId, warrantyIdsByInventoryId)) {
        return getDefaultWarrantyId(warrantyOptions)
    }
    // warranty option is selected
    else {
        const selectedId = warrantyIdsByInventoryId[inventoryId]

        // selected a valid warranty option
        if(hasProp(selectedId, warrantyOptions)) {
            return selectedId
        }
        // not a valid option
        else {
            return getDefaultWarrantyId(warrantyOptions)
        }
    }
}

export const getItemCost = (inventoryId, cart) => {
    return isMakeOffer(inventoryId, cart)
        ? getMakeOfferPrice(inventoryId, cart) || 0
        : get('retailPrice', 0, cart.items[inventoryId])
}

export const getSelectedWarrantyInfo = (warrantyOptions, cart, inventoryId) => warrantyOptions[getSelectedWarrantyId(warrantyOptions, cart, inventoryId)]

export const getDefaultWarrantyId = (warrantyOptions) => {
    return reduce((acc, x) => {
        return x.isStandard
            ? x.id
            : acc
    }, null, warrantyOptions)
}

export const getDefaultWarrantyInfo = (warrantyOptions) => warrantyOptions[getDefaultWarrantyId(warrantyOptions)]

export const getNonDefaultWarrantyInfos = (warrantyOptions) => {
    return filter(x => !x.isStandard, warrantyOptions)
}

export const getWarrantyInfoById = (warrantyId, warrantyOptions) => warrantyOptions[warrantyId]

// it costs something
export const isPricedWarranty = warrantyInfo => warrantyInfo
    ? warrantyInfo.basePrice > 0 || warrantyInfo.addPercentage > 0
    : 0

// itemPrice could be the retailPrice or an offer price
export const getWarrantyCost = (warrantyInfo, itemPrice) => {
    if(!warrantyInfo) return 0

    const addFactor = warrantyInfo.addPercentage
        ? (warrantyInfo.addPercentage / 100)
        : 0

    return roundTwoDigits(warrantyInfo.basePrice + (itemPrice * addFactor))
}

export const isMakeOffer = (inventoryId, cart) => {
    // user has already offerred a price
    if(hasItem(cart.offers, inventoryId)) return true
    // if the item isn't in the cart, don't consider it a make-offer part
    else if(!hasItem(cart.items, inventoryId)) return false
    // it's make offer if there is no retailPrice
    else return !hasRetailPrice(cart.items?.[inventoryId]?.retailPrice)
}

export const getMakeOfferPrice = (inventoryId, cart) => get(inventoryId, null, cart.offers)

export const getWarrantyInfo = (inventoryId, cart) => {
    // if this is a make-offer part, then no warranty
    if(isMakeOffer(inventoryId, cart)) return null

    return get(inventoryId, null, cart.warranties)
}

// get items that haven't been updated in a certain amount of time
export const getOldItems = (howOld = oneHour) => {
    const cart = getCart()
    const now = Date.now()

    const itemsLastUpdated = cart.itemsLastUpdated || {}

    // Need to use cart.items in case the itemsLastUpdated object doesn't exist
    // (that could happen for carts initialized before this change). We still
    // want to check those items.
    return Object.keys(cart.items)
        .map((inventoryId) => {
            // zero will force an update
            const lastUpdatedStamp = itemsLastUpdated[inventoryId] || 0

            if(now - lastUpdatedStamp > howOld) {
                return cart.items[inventoryId]
            }
            else {
                return null
            }
        })
        .filter(Boolean)
}
