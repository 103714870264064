import urlJoin from 'url-join';
import { get } from '@cullylarson/f';
import { bearer, responseData } from '@@client/lib/request';
export function refresh(apiUrl, refreshToken) {
  return fetch(urlJoin(apiUrl, '/api/v1/account/refresh'), {
    method: 'post',
    headers: {
      'Authorization': bearer(refreshToken),
      'Content-Type': 'application/json'
    }
  }).then(responseData).then(function (_ref) {
    var response = _ref.response,
        data = _ref.data;

    if (response.ok) {
      return {
        success: true,
        payload: data
      };
    } else {
      return {
        success: false,
        unauthorized: response.status === 401,
        errors: get('error', [], data)
      };
    }
  });
}
export function visitor(apiUrl) {
  return fetch(urlJoin(apiUrl, '/api/v1/account/visitor'), {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        payload: data
      };
    } else {
      return {
        success: false,
        errors: get('error', [], data)
      };
    }
  });
}