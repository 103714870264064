import {useEffect, useRef} from 'react'
import ReactDom from 'react-dom'
import noScroll from 'no-scroll'
import {CSSTransition} from 'react-transition-group'
import FocusTrap from 'focus-trap-react'
import {isBrowser} from '@@client/lib/nextjs'
import {stopPropagation} from '@@client/lib/events'
import {classnames} from '@@client/lib/classes'
import CloseButton from '@@client/components/CloseButton'

// NOTE: most of the 'settings' props can't be changed after first render. well, they can but the changes likely will be ignored
export default function Modal({
    children,
    title,
    titleIcon = null,
    subtitle,
    footer,
    isOpen = false,
    onClose = () => {},
    showCloseButton = true,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    initialFocus = undefined,
    className = null,
}) {
    const container = useRef(isBrowser() && document.createElement('div'))

    const renderHeader = ({title, titleIcon, subtitle}) => {
        if(!title && !subtitle) return ''

        const renderTitle = () => {
            if(!title) return null

            return (
                <>
                    <div className='title'>
                        {titleIcon ? <span className='title-icon'>{titleIcon}</span> : null}
                        <span>{title}</span>
                    </div>
                    <style jsx>
                        {`
                            @import '@css/variables.css';

                            .title {
                                display: flex;
                                font-size: 24px;
                                color: $c--blue--darker;
                                justify-content: center;
                                align-items: center;
                            }

                            .title-icon {
                                margin-right: 10px;
                            }
                        `}
                    </style>
                </>
            )
        }

        return (
            <>
                <header>
                    {renderTitle()}
                    {subtitle ? (<div className='subtitle'>{subtitle}</div>) : ''}
                </header>
                <style jsx>
                    {`
                        @import '@css/variables.css';

                        header {
                            padding: 25px;
                            border-bottom: 1px solid $c--blue--darker;
                        }

                        .subtitle {
                            font-style: italic;
                            margin-top: 5px;
                        }
                    `}
                </style>
            </>
        )
    }

    const renderFooter = footer => {
        if(!footer) return ''

        return (
            <>
                <footer>
                    {footer}
                </footer>
                <style jsx>
                    {`
                        @import '@css/variables.css';

                        footer {
                            background: #F0F1F6;
                            padding: 25px;
                            text-align: center;
                            font-size: 20px;
                            color: $c--blue--darker;

                            :global(a),
                            :global(.m--as-link) {
                                text-decoration: none;
                            }
                        }
                    `}
                </style>
            </>
        )
    }

    const handleOverlayClick = (() => {
        if(!closeOnOverlayClick) return () => {}

        // give a bit of a delay before the overlay click works to prevent accidental double-click
        let active = false

        setTimeout(() => { active = true }, 400)

        // stopPropagation prevents the click event from bubbling if parent is a link.
        return stopPropagation(() => {
            if(!active) return

            onClose()
        })
    })()

    useEffect(() => {
        const on = () => document.body.appendChild(container.current)
        const off = () => document.body.removeChild(container.current)

        on()

        return off
    }, [])

    useEffect(() => {
        const on = () => noScroll.on()
        const off = () => noScroll.off()

        if(isOpen) on()
        else off()

        return off
    }, [isOpen])

    useEffect(() => {
        if(!closeOnEsc) return

        const handleKeyDown = e => {
            if(event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
                onClose()
            }
        }

        const on = () => document.addEventListener('keydown', handleKeyDown)
        const off = () => document.removeEventListener('keydown', handleKeyDown)

        if(isOpen) on()
        else off()

        return off
    }, [isOpen])

    const classNames = classnames([
        className,
        'modal',
    ])

    return !container.current ? null : ReactDom.createPortal(
        <>
            <CSSTransition
                in={isOpen}
                classNames='transition'
                appear
                mountOnEnter
                unmountOnExit
                timeout={200}
            >
                <FocusTrap focusTrapOptions={{initialFocus}}>
                    <div className='overlay' onClick={handleOverlayClick}>
                        {/* stopPropagation prevents the click event from bubbling to the overlay and closing the modal */}
                        <div className={classNames} role='dialog' aria-modal='true' onClick={stopPropagation()}>
                            {showCloseButton ? (<div className='close'><CloseButton onClick={onClose} /></div>) : null}
                            {renderHeader({title, titleIcon, subtitle})}
                            <div className='modal-body'>
                                {children}
                            </div>
                            {renderFooter(footer)}
                        </div>
                    </div>
                </FocusTrap>
            </CSSTransition>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .overlay {
                        transition: unset;
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.6);
                        z-index: 999;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.transition-enter {
                            opacity: 0;

                            & .modal {
                                transform: scale(0);
                                top: -100px;
                            }
                        }

                        &.transition-enter-active {
                            transition: all 200ms ease-out;
                            opacity: 1;

                            & .modal {
                                transition: all 200ms ease-out;
                                transform: scale(1);
                                top: 0;
                            }
                        }

                        &.transition-exit {
                            opacity: 1;

                            & .modal {
                                transform: scale(1);
                                top: 0;
                            }
                        }

                        &.transition-exit-active {
                            transition: all 200ms ease-out;
                            opacity: 0;

                            & .modal {
                                transition: all 200ms ease-out;
                                transform: scale(0);
                                top: 100px;
                            }
                        }
                    }

                    .modal {
                        position: relative;
                        flex: 0 1 auto;
                        min-width: 250px;
                        max-width: 800px;
                        background: white;
                        max-height: 100%;
                        overflow: auto;
                        margin: 20px;
                        border-radius: 6px;
                        box-shadow: 0 15px 25px 10px rgba(0, 0, 0, .3);

                        @media(--xxs) {
                            width: 100%;
                        }
                    }

                    .modal:not(.no-body-styles) .modal-body {
                        padding: 25px 80px;
                        max-width: 590px;
                        margin: 0 auto;

                        @media(--xs) {
                            padding: 25px;
                        }
                    }

                    .close {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                `}
            </style>
        </>,
        container.current,
    )
}
